var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('section', {
    attrs: {
      "id": "faq-tabs"
    }
  }, [_c('app-collapse', {
    staticClass: "mt-2",
    attrs: {
      "accordion": "",
      "type": "margin"
    }
  }, _vm._l(_vm.panduanData, function (item, i) {
    return _c('app-collapse-item', {
      key: i,
      attrs: {
        "title": item.name
      }
    }, [_c('div', {
      staticClass: "ck-content",
      domProps: {
        "innerHTML": _vm._s(item.content)
      }
    })]);
  }), 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }