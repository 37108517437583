<template>
  <div>
    <!-- frequently asked questions tabs pills -->
    <b-overlay :show="loading">
      <section id="faq-tabs">
        <app-collapse accordion type="margin" class="mt-2">
          <app-collapse-item
            v-for="(item, i) in panduanData"
            :key="i"
            :title="item.name"
          >
            <div class="ck-content" v-html="item.content" />
          </app-collapse-item>
        </app-collapse>
      </section>
    </b-overlay>
  </div>
</template>

<script>
import { BOverlay } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BOverlay,
  },
  data() {
    return {
      panduanData: {},
      loading: false,
    }
  },

  methods: {
    fetchData() {
      this.loading = true
      this.$store
        .dispatch('panduan/publicIndex')
        .then((res) => {
          this.loading = false
          this.panduanData = res.data.data
        })
        .catch((err) => {
          this.loading = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Error `,
              icon: 'XIcon',
              variant: 'danger',
              text: err.response.data.message,
            },
          })
        })
    },
  },

  mounted() {
    this.fetchData()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-faq.scss';

.ck-content img {
  max-width: 100%;
}
</style>
